<template>
	<div class="page ">
		<div v-if="!token" class="page pagebg" :style="{backgroundImage:'url(./images/qrcodbg.jpg)'}">
			<div style="width: 500px; margin:0 auto; text-align: center;">
				<div style="line-height: 200px; color: #fff; font-size: 20px;">{{examName}}</div>
				<div  style="text-align: left;  padding-bottom: 20px; width: 300px; margin: 0 auto; color: #fff; font-weight: bold;">
					开始时间：{{startDate}}
					<br>
					考试时长：{{examMin}}分钟
				</div>
				<div style="width: 300px; margin: 0 auto;">
					<el-card class="box-card" v-if="pmid">
						<div v-if="startExam" style="width: 258px; height: 258px;  text-align: center;">
							<div v-if="userAcc" >
								<el-row>
									<el-input v-model="stuinfo.stu_name" type="text" size="small" placeholder="请输入姓名"></el-input>
								</el-row>
								<el-row style="margin-top: 20px;">
									<el-input v-model="stuinfo.stu_code"  type="text" size="small" placeholder="请输入学生号"></el-input>
								</el-row>
								<el-row style="margin-top: 20px;">
									<el-select v-model="stuinfo.class_name"  style="width: 100%;" size="small" placeholder="请选择班级">
										<el-option v-for="(cls,idx) in classlist" :key="idx" :label="cls.class_name" :value="cls.class_name"></el-option>
									</el-select>
								</el-row>
								<el-row style="margin-top: 20px;">
									<el-input  v-model="stuinfo.phone" type="text" size="small" placeholder="请输入手机号"></el-input>
								</el-row>
								
								<el-row style="margin-top: 20px;">
									<el-button style="width: 100%;" type="primary" @click="pcLogin">开始答题</el-button>
								</el-row>
								
							</div>
							<img v-else :src="imgurl" style="width: 258px; height: 258px;">
						</div>
						
						
						<div v-else style="width: 258px; height: 258px; line-height: 258px; text-align: center;">
							未到开始时间
						</div>
					</el-card>

				</div>
				<div style="line-height: 200px; color: #fff; font-size: 18;">
					请扫码进入考试
					<span style="margin-left: 20px; font-size: 14px; cursor: pointer; color: #79BBFF;" @click="userAcc=!userAcc">{{userAcc?'扫码登录':'无法扫码？点击登录'}}</span>
				</div>
			</div>
		</div>

		<div v-else class="page pagebg" :style="{backgroundImage:'url(./images/qrcodbg.jpg)'}">
			<div style="width: 800px; margin:0 auto; height: 100%;">
				<iframe :src="'https://examsh.mmteck.cn/myexam/index.html?auth='+token+'#?exam_id='+examid"
					style="width: 100%; height: 100%; overflow: auto; border: 0; background-color: #fff;">

				</iframe>
			</div>
		</div>



	</div>




</template>

<script>
	export default {

		data() {
			return {
				examName: "",
				pmid: 2,
				examid: 0,
				imgurl: "",
				session_id: '',
				token: "",
				startDate:"",
				examMin:"",
				startExam:false,
				starttime:0,
				endtime:0,
				now:0,
				userAcc:false,
				classlist:[],
				stuinfo:{
					phone:"",
					stu_name:"",
					stu_code:"",
					class_name:"",
				}
			}
		},
		mounted() {
			

			this.pmid = this.$route.query.pm
			this.examid = this.$route.query.exam
			this.getData()
			this.getClass()
			
			window.addEventListener('message', (event)=>{
			  if(event.data=="close"){
				  window.location.reload()
			  }
			}, false);
		
		},

		methods: {
			getClass(){
				
				this.$http.post("/api/get_classes", {
					pmid:this.pmid
				}).then(res => {
					this.classlist=res.data
				})
			},
			getData() {
				this.$http.post("/api/pc_exam_info", {
					id: this.examid
				}).then(res => {
					this.examName = res.data.exam_title
					document.title=res.data.exam_title
					this.startDate = res.data.sdate
					this.examMin = res.data.minute
					this.starttime = new Date(res.data.sdate.replace(/-/g,'/')).getTime()
					this.endtime =  new Date(res.data.edate.replace(/-/g,'/')).getTime()
					this.now = res.data.now
					if(this.starttime<res.data.now&&this.endtime>res.data.now){
						this.startExam = true
					}else{
						let t = setInterval(()=>{
							this.now+=1000
						
							if(this.starttime<this.now&&this.endtime>this.now){
								this.startExam = true
								clearInterval(t)
							}
						},1000)
					}
				})
				this.getQrcode()
			},
			getQrcode() {
				this.$http.post("/api/get_pc_login_qrcode", {
					pmid: this.pmid
				}).then(res => {
					
					this.imgurl = res.data.url
					this.session_id = res.data.session_id
					
					this.checkQrcode()
				})
			},
			checkQrcode() {
				this.$http.post("/api/check_pc_login_qrcode", {
					session_id: this.session_id,
					noloading:true
				}).then(res => {
					if (res.data.code) {
						if (res.data.msg == "waiting") {
							setTimeout(() => {
								if(!this.token){
									this.checkQrcode()
								}
								
							}, 2000)
						} else if (res.data.msg == "refresh") {
							this.getQrcode()
						} else {
							if (res.data.msg) {
								this.token = res.data.msg
							}
						}
					}
				})
			},
			pcLogin(){
				this.stuinfo.pmid=this.pmid
				this.$http.post("/api/bind_pc_student", this.stuinfo).then(res => {
					if(res.data.stu){
						this.token = res.data.stu.token
					}
				})
			}
		}
	}
</script>
<style scoped>
	.pagebg {
		background-size: 100% 100%;
	}
</style>
